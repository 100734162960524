import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostNav from "../components/postNav"
import PostCard from "../components/postCard"
import PageHeadImg from "../../content/assets/background.webp"
import Logo from "../../content/assets/logo_ogp.webp"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Unlimish"
        keywords={[
          `creator`,
          `unlimish`,
          `portfolio`,
          `motion graphics`,
          `design`,
        ]}
        image={Logo}
      />
      {/* <Bio /> */}
      {data.site.siteMetadata.description && (
        <header className="page-head">
          <img src={PageHeadImg} alt="Logo in Blender" />
          <div className="page-head-content">
            <div className="page-head-title">
              <h2 className="f-item">{data.site.siteMetadata.description}</h2>
              <Link className="button primary text-cap f-item" to={`/about`}>
                REEL 2023
              </Link>
            </div>
          </div>
        </header>
      )}
      <PostNav />
      <div id="post-feed">
        {posts.map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>
      <style id="style">
        {`
          #show-all {
            color: #cf3177 !important;
            border: 1px solid #e6a1bf !important;
            background: #f4e7ed;
          }
        `}
      </style>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
